import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { Box, Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { reportErrorToConsole } from '@src/services/error-logger'
import { Breadcrumb, InputTextField, PageError, PageSpinner } from '../../components'
import CreditDecisionIceberg from './components/creditDecision'
import IFiScore from './components/iFiScore'
import NormIceberg from './components/normIceberg'
import { TRootState, useAppSelector } from '../../data/store'
import { creditSelectors, creditEffects } from '../../data/store/CreditApplication'
import { documentSelectors } from '../../data/store/Document'
import SelectComponent from '../../components/SelectComponent'
import {
  buildFullCreditApplicationSchema,
  CreditApplication,
  EFinancingProgram,
  SelectValueListItem,
} from '../../data/types'
import ApplicantInformations from './components/applicantInformations'
import { appSelectors } from '../../data/store/AppStore'
import { getNumberFromQueryString } from '../../services/url'
import { TranslatedEnum } from '../../data/types/TranslatedEnum'
import RefinancingSection from './components/refinancingSection'
import { userSelectors } from '../../data/store/UserStore'
import ProofOfReleaseSection from './components/proofOfReleaseSection'
import CustomerAccount from './components/customerAccount'
import CreditDecisionHistoryDialog from '../ViewCreditApplicationPage/components/creditDecisionHistoryDialog'
import { CreditReport } from '../../data/types/CreditReportSchema'

enum Dialog {
  CreditDecisionHistory = 'creditDecisionHistory',
  None = '',
}

const CreditDecisionPage = (): JSX.Element | null => {
  const { financingProgramId } = useParams<{ financingProgramId: EFinancingProgram }>()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [applicantCreditReport, setApplicantCreditReport] = React.useState<CreditReport | null>(null)
  const [coAplicantCreditReport, setCoAplicantCreditReport] = React.useState<CreditReport | null>(null)
  const tabIndex = getNumberFromQueryString(searchParams, 'tabIndex', 1)
  const dispatchEffect = useSideEffect()
  const normsMessage = useAppSelector(creditSelectors.getCurrentCreditApplicationNorms)
  const isLoading = useAppSelector(creditSelectors.isLoadingCreditApplication)
  const creditApplication = useAppSelector(creditSelectors.getCurrent)
  const error = useAppSelector(appSelectors.getBusinessError)
  const areAllTasksRequiredForCreditCompleted = useAppSelector(creditSelectors.areAllTasksRequiredForCreditCompleted)
  const areAllDocumentsRequiredForCreditApproved = useAppSelector(documentSelectors.areAllDocumentsRequiredForCredit)
  const user = useAppSelector(userSelectors.getUser)
  const finalDecisionIsPending = useAppSelector(creditSelectors.isFinalDecisionPending)
  const shouldDsiplayIFiScore = useAppSelector(creditSelectors.getFinancingProgramConfigs).useIFiScore
  const isRefinancing = useAppSelector(creditSelectors.isRefinancing)
  const financingConfig = useAppSelector((state: TRootState) =>
    appSelectors.getFinancingConfig(state, financingProgramId),
  )
  const editDisabled =
    isRefinancing ||
    creditApplication?.editLocked ||
    finalDecisionIsPending ||
    !user?.rights.canEditCreditApp ||
    creditApplication?.loanId != null

  const canApprove = areAllTasksRequiredForCreditCompleted && areAllDocumentsRequiredForCreditApproved
  const {
    register,
    reset,
    formState: { errors },
  } = useForm<CreditApplication>({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
    defaultValues: creditApplication!,
    resolver: yupResolver(buildFullCreditApplicationSchema(financingConfig)),
  })

  const [openDialog, setOpenDialog] = React.useState<Dialog>(Dialog.None)

  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum
  const financingProgramEnum = useAppSelector(appSelectors.getFinancingProgramEnum)
  const merchant = useAppSelector(creditSelectors.getMerchant)
  const breadCrumbs = [
    { path: '/', label: t('breadcrumbs.home') },
    { path: '/Applications/browse', label: t('breadcrumbs.creditApplication') },
    {
      path: `/Applications/${creditApplication?.financingProgramId}/${creditApplication?.id}/view`,
      label: (t('breadcrumbs.application') as string).concat(` #${creditApplication?.referenceNumber}`),
    },
    { path: '#', label: t('breadcrumbs.decision') },
  ]

  useEffect(() => {
    reset(creditApplication!)
  }, [creditApplication, reset])

  const refreshCreditReport = (applicant: 'applicant' | 'coApplicant') => {
    if (creditApplication?.id)
      dispatchEffect(
        creditEffects.refreshCreditReport({
          creditApplicationId: creditApplication.id,
          financingProgramId: creditApplication.financingProgramId,
          isApplicant: applicant === 'applicant',
          versionTag: creditApplication.versionTag!,
        }),
      ).catch(reportErrorToConsole)
  }

  const closeDialog = React.useCallback(() => setOpenDialog(Dialog.None), [setOpenDialog])
  return (
    <>
      <PageSpinner isLoading={isLoading} />
      {!isLoading && creditApplication && (
        <>
          <Breadcrumb trees={breadCrumbs} />
          <PageError errors={error} />
          <Box sx={{ m: 2 }} />

          <Box sx={{ display: 'flex' }}>
            <SelectComponent
              items={Object.values(financingProgramEnum).map((financingProgram) => {
                const data: SelectValueListItem = {
                  label: financingProgram[lang],
                  value: financingProgram.id,
                }
                return data
              })}
              label={t('editCreditApplication.financingProgramType') as string}
              {...register('financingProgramId')}
              error={errors?.financingProgramId}
              disabled
            />
            <InputTextField value={merchant?.name} label={t('common.merchant')} disabled />
          </Box>

          <Grid container textAlign="center" marginBottom={2}>
            {creditApplication.finalCreditDecision && (
              <>
                {shouldDsiplayIFiScore && (
                  <Grid item xs={12} md={3} border="1px solid" borderRadius={2} p={4}>
                    <IFiScore
                      creditApplication={creditApplication}
                      userCanReadTraces={user?.rights.canReadTraces === true}
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={3} border="1px solid" borderRadius={2} p={4}>
                  <NormIceberg
                    normDecision={creditApplication.normsCreditDecision}
                    normsMessage={normsMessage}
                    applicationSuggestedAmount={creditApplication.normsCreditDecision?.maxAmountFinanced ?? 0}
                  />
                </Grid>
                <Grid item xs={12} md={3} border="1px solid" borderRadius={2} p={4}>
                  <CustomerAccount
                    creditApplication={creditApplication}
                    userCanApproveCreditApplication={user?.rights.canMakeDecision === true}
                    editDisabled={editDisabled}
                  />
                </Grid>
                {financingProgramId === EFinancingProgram.Auto && (
                  <Grid item xs={12} md={3} border="1px solid" borderRadius={2} p={4}>
                    <RefinancingSection
                      creditApplication={creditApplication}
                      userCanApproveCreditApplication={user?.rights.canMakeDecision === true}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={3} border="1px solid" borderRadius={2} p={4}>
                  <CreditDecisionIceberg
                    openHistoryDialog={() => setOpenDialog(Dialog.CreditDecisionHistory)}
                    financingProgramId={financingProgramId!}
                    creditApplication={creditApplication}
                    canApprove={canApprove}
                    editDisabled={editDisabled}
                    userCanApproveCreditApplication={user?.rights.canMakeDecision === true}
                  />
                </Grid>
                {creditApplication.proofsOfRelease && financingProgramId === EFinancingProgram.Auto && (
                  <Grid item xs={12} md={3} border="1px solid" borderRadius={2} p={4}>
                    <ProofOfReleaseSection
                      creditApplication={creditApplication}
                      applicantCreditReport={applicantCreditReport}
                      coApplicantCreditReport={coAplicantCreditReport}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
          <CreditDecisionHistoryDialog
            open={openDialog === Dialog.CreditDecisionHistory}
            creditApplicationId={creditApplication.id}
            onClose={closeDialog}
            financingProgramId={financingProgramId!}
          />
          <ApplicantInformations
            creditApplication={creditApplication}
            applicantCreditReport={applicantCreditReport}
            coApplicantCreditReport={coAplicantCreditReport}
            setApplicantCreditReport={setApplicantCreditReport}
            setCoApplicantCreditReport={setCoAplicantCreditReport}
            refreshReport={refreshCreditReport}
            selectedTabIndex={tabIndex}
            userCanRefreshCreditReport={user?.rights.canRefreshCreditReport === true}
            editDisabled={editDisabled}
          />
        </>
      )}
    </>
  )
}

export default CreditDecisionPage
