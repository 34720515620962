import { Grid } from '@mui/material'
import { DatePicker } from '@src/components/DatePicker'
import InputTextField from '@src/components/InputTextField'
import SelectComponent from '@src/components/SelectComponent'
import { Control, FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CreditApplication, EPaymentFrequency, EPaymentPlan } from '@src/data/types'
import { ProductsWorksheet, ProductsWorksheetDto } from '@src/data/types/ProductsWorksheetSchema'
import { formatDate } from '@src/services/Formatter'
import { useEffect, useMemo, useState } from 'react'
import { useProductsFirstPaymentDateOptions, useProductsTerms } from '../../editWorksheet-hooks'

type Props = {
  creditApplication: CreditApplication
  worksheet: ProductsWorksheet
  control: Control<ProductsWorksheetDto>
  register: UseFormRegister<ProductsWorksheetDto>
  watch: UseFormWatch<ProductsWorksheetDto>
  setValue: UseFormSetValue<ProductsWorksheetDto>
  getValues: UseFormGetValues<ProductsWorksheetDto>
  errors: FieldErrors<ProductsWorksheetDto>
  merchantPayments: { label: string; value: string }[]
  disabledAmountInput?: boolean
  maxTermDuration: number
}

const WorksheetDetails = ({
  creditApplication,
  worksheet,
  register,
  errors,
  control,
  watch,
  setValue,
  getValues,
  merchantPayments,
  disabledAmountInput,
  maxTermDuration,
}: Props) => {
  const { t } = useTranslation()
  const deliveryOn = watch('deliveryOn')
  const paymentFrequency = watch('paymentFrequency')
  const [firstPaymentDateOptions, setFirstPaymentOptions] = useState<Date[]>([])
  const computeProductsFirstPaymentDateOptions = useProductsFirstPaymentDateOptions()
  const possibleTerms = useProductsTerms(maxTermDuration)

  const [isVariableInterest, setIsVariableInterest] = useState(false)
  const loanTermChoicesList =
    possibleTerms.map((item) => ({
      label: `${item} ${t('worksheet.monthLowerCase')}`,
      value: item,
    })) || []
  const paymentPlanId = watch('paymentPlanId') as EPaymentPlan
  const selectedPlan = useMemo(
    () => merchantPayments?.find((p: { value }) => p.value === paymentPlanId),
    [merchantPayments, paymentPlanId],
  )

  useEffect(() => {
    const isVariable = Boolean(paymentPlanId && paymentPlanId !== EPaymentPlan.regularDailyInterests)

    if (isVariable && selectedPlan) {
      setValue('term', Number(selectedPlan.value))
    }

    setIsVariableInterest(isVariable)
  }, [paymentPlanId, merchantPayments, selectedPlan, selectedPlan?.value, setValue])

  // recompute first payment options and date
  useEffect(() => {
    if (deliveryOn && paymentFrequency) {
      const options = computeProductsFirstPaymentDateOptions(deliveryOn, paymentFrequency)
      setFirstPaymentOptions(options)

      const currentFirstPaymentDate = getValues('firstPaymentOn')
      const currentFirstPaymentDateAsString = formatDate(currentFirstPaymentDate)
      const availableDatesAsString = options.map((item) => formatDate(item))

      if (availableDatesAsString.length > 0 && !availableDatesAsString.includes(currentFirstPaymentDateAsString)) {
        setValue('firstPaymentOn', options[0])
      }
    }
  }, [deliveryOn, computeProductsFirstPaymentDateOptions, getValues, setValue, paymentFrequency])

  return (
    <Grid container spacing={2} mt={0.5} mb={2}>
      <Grid item xs={6} md={4}>
        <InputTextField
          label={t('worksheetCommon.amountRequested')}
          fullWidth
          disabled={disabledAmountInput}
          {...register('amountRequested')}
          error={errors.amountRequested}
          defaultValue={worksheet?.amountRequested}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <DatePicker
          name="deliveryOn"
          control={control}
          error={errors?.deliveryOn}
          label={t('worksheetCommon.activationDate')}
          disablePast
          minDate={new Date()}
          maxDate={new Date(creditApplication?.expiresOn)}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <SelectComponent
          disabled={isVariableInterest}
          items={loanTermChoicesList}
          label={t('worksheet.term') as string}
          {...register('term')}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <SelectComponent
          items={Object.values(EPaymentFrequency).map((item) => {
            return { label: `worksheet.${item}`, value: item }
          })}
          label={t('worksheet.paymentFrequency') as string}
          {...register('paymentFrequency')}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <DatePicker
          name="firstPaymentOn"
          control={control}
          error={errors?.firstPaymentOn}
          label={t('worksheet.firstPaymentOn')}
          disablePast
          minDate={firstPaymentDateOptions[0]}
          maxDate={firstPaymentDateOptions[firstPaymentDateOptions.length - 1]}
          disabled={!paymentFrequency}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <SelectComponent
          items={merchantPayments?.map((item) => ({
            label: item.label.includes(EPaymentPlan.regularDailyInterests)
              ? t(`enum.ePaymentPlan.${item.label as EPaymentPlan}`)
              : item.label,
            value: item.value,
          }))}
          label={t('worksheet.paymentPlan') as string}
          {...register('paymentPlanId')}
          error={errors?.paymentPlanId}
        />
      </Grid>
    </Grid>
  )
}

export default WorksheetDetails
