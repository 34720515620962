import { Container, CssBaseline } from '@mui/material'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { reportErrorToConsole } from '@src/services/error-logger'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter as Router } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../data/store'
import { appSelectors, appStoreEffects } from '../../data/store/AppStore'
import { userActions, userEffects, userSelectors } from '../../data/store/UserStore'
import LoginPage from '../Home/LoginPage'
import AlertBanner from './AlertBanner'
import AppHeader from './AppHeader'
import ConflictDialog from './components/ConflictDialog'
import TopLevelRoutes from './Routes'
import LocaleProvider from './components/LocaleProvider'
import ThemeProvider from './components/ThemeProvider'

const App = (): JSX.Element | null => {
  const dispatch = useAppDispatch()
  const [isLoaded, setLoaded] = useState(false)
  const dispatchEffect = useSideEffect()
  const needAuth = useAppSelector(userSelectors.needToAuthenticate)
  const isIcebergGrDevs = useAppSelector(userSelectors.isIcebergGrDevs)
  const conflict = useAppSelector(appSelectors.conflict)
  const deprecated = useAppSelector(appSelectors.isDeprecated)
  const { pathname } = window.location
  const isAuthenticate = pathname === '/auth-page'
  const { t } = useTranslation()

  const hasAlertBanner = isIcebergGrDevs || (import.meta.env.PROD && deprecated)

  useEffect(() => {
    dispatch(userActions.loadPreferences())
    Promise.all([dispatchEffect(appStoreEffects.initialize()), dispatchEffect(userEffects.loadUser())])
      .then(() => setLoaded(true))
      .catch(reportErrorToConsole)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if (!isLoaded) return null
  return (
    <ThemeProvider>
      <LocaleProvider>
        <CssBaseline />
        <Router>
          {needAuth && !isAuthenticate && <LoginPage />}
          {!needAuth && (
            <>
              <AlertBanner message="Production" show={isIcebergGrDevs} />
              <AlertBanner message={t('common.deprecated')} variant="error" show={import.meta.env.PROD && deprecated} />
              <AppHeader hasAlertBanner={hasAlertBanner} />
              <Container maxWidth="xl" disableGutters>
                <TopLevelRoutes />
              </Container>
            </>
          )}
          <ConflictDialog open={conflict !== null} conflict={conflict} />
        </Router>
      </LocaleProvider>
    </ThemeProvider>
  )
}

export default App
