import {
  Alert,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  LinearProgress,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import {
  Control,
  FieldError,
  FieldErrorsImpl,
  Merge,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  useWatch,
} from 'react-hook-form'
import { Search as SearchIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { EditAutoRslaWorksheetDto } from '@src/data/types/AutoRslaWorksheet'
import { getApiClient } from '@src/services/api-client'
import { InputTextField, MultipleSelectCheckmarks, SelectComponent } from '../../../components'
import vehicleDecoderApi from '../../../data/api/vehicle-decoder/vehicle-decoder-api'
import { useAppSelector } from '../../../data/store'
import { userSelectors } from '../../../data/store/UserStore'
import {
  Additions,
  ECarCondition,
  ECarConditionList,
  Merchant,
  SelectValueListItem,
  Vehicle,
  VehicleSchema,
} from '../../../data/types'
import { formatCar } from '../../../services/Formatter'

type Props = {
  defaultVehicle: Vehicle | undefined | null
  merchant: Merchant | null
  setValue: UseFormSetValue<EditAutoRslaWorksheetDto>
  getValues: UseFormGetValues<EditAutoRslaWorksheetDto>
  control: Control<EditAutoRslaWorksheetDto>
  register: UseFormRegister<EditAutoRslaWorksheetDto>
  watch: UseFormWatch<EditAutoRslaWorksheetDto>
  errors: Merge<FieldError, FieldErrorsImpl<Vehicle>> | undefined
  title: string
}

const apiClient = getApiClient()

const VehicleForm = ({
  defaultVehicle,
  merchant,
  setValue,
  getValues,
  control,
  register,
  watch,
  errors,
  title,
}: Props) => {
  const { t } = useTranslation()
  const user = useAppSelector(userSelectors.getUser)

  const [decodedVehicles, setDecodedVehicles] = useState<Vehicle[]>([])
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | undefined | null>(defaultVehicle)
  const [notFound, setNotFound] = useState(false)
  const [isDecoding, setIsDecoding] = useState(false)

  const thirdPartySearchOverrideWatch = useWatch({
    control,
    name: 'vehicle.thirdPartySearchOverride',
  })

  const resetVehicle = () => {
    const tempVehicle = {
      ...VehicleSchema.getDefault(),
      vin: getValues(`vehicle.vin`),
    }

    setValue('vehicle', tempVehicle)

    setDecodedVehicles([])
  }

  const handleThirdPartySearchOverrideChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(`vehicle.thirdPartySearchOverride`, event.target.checked)
    if (event.target.checked === false) resetVehicle()
  }

  const handleAdditionsChanged = (additionCodes: string[] | number[]) => {
    setValue(
      `vehicle.additions`,
      selectedVehicle?.additions.filter((x) => additionCodes.some((code) => x.code === code)) ?? ([] as Additions[]),
    )
  }

  const decodeVin = async () => {
    const vin = getValues(`vehicle.vin`)
    if (vin?.length === 17 && apiClient && user) {
      setIsDecoding(true)
      resetVehicle()
      await vehicleDecoderApi
        .decode(apiClient, vin, user.id, merchant?.province ?? 'QC')
        .then((ret) => {
          if (ret[0]) {
            const first = ret[0]
            first.vin = vin
            setDecodedVehicles(ret)
            setSelectedVehicle(first)
            setValue('vehicle', first)
            setNotFound(false)
          } else {
            setNotFound(true)
          }
          setIsDecoding(false)
        })
        .catch(() => {
          setIsDecoding(false)
          setNotFound(true)
        })
    }
  }

  const handleVehicleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const vehicle = decodedVehicles.find((v) => v.thirdPartyId && v.thirdPartyId.toString() === event.target.value)
    if (vehicle) {
      vehicle.vin = getValues(`vehicle.vin`)
      setSelectedVehicle(vehicle)
      setValue('vehicle', vehicle)
    }
  }

  return (
    <>
      <Divider style={{ fontSize: 25 }} flexItem>
        <Typography variant="h4" component="span" gutterBottom>
          {title}
        </Typography>
      </Divider>

      <Grid container marginTop={2} marginBottom={2} spacing={2} sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <Grid
          container
          item
          xs={12}
          direction="row"
          justifyContent={!selectedVehicle ? 'center' : 'space-between'}
          alignItems="center"
        >
          <Grid item xs={9} md={2.5}>
            <InputTextField
              InputProps={{
                endAdornment: (
                  <IconButton onClick={decodeVin}>
                    <SearchIcon />
                  </IconButton>
                ),
                style: { textTransform: 'uppercase' },
              }}
              label={t('worksheet.vin')}
              {...register(`vehicle.vin`)}
              error={errors?.vin}
            />
          </Grid>
        </Grid>

        <>
          <Grid item xs={12}>
            {notFound ? (
              <Alert severity="error">{t('worksheet.vehicleNotFound')}</Alert>
            ) : (
              <FormControl>
                {decodedVehicles.length > 0 && (
                  <Typography variant="body1">{t('worksheet.availableVehicles')}</Typography>
                )}
                <RadioGroup aria-labelledby="vehicles-label" onChange={(event) => handleVehicleChange(event)}>
                  <Stack>
                    {decodedVehicles.map((v) => (
                      <FormControlLabel
                        checked={selectedVehicle?.thirdPartyId === v.thirdPartyId}
                        key={v.thirdPartyId}
                        value={v.thirdPartyId}
                        control={<Radio />}
                        label={formatCar(v)}
                      />
                    ))}
                  </Stack>
                </RadioGroup>
              </FormControl>
            )}

            {(notFound || thirdPartySearchOverrideWatch) && (
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={defaultVehicle?.thirdPartySearchOverride}
                      onChange={handleThirdPartySearchOverrideChanged}
                    />
                  }
                  label={t('worksheet.overrideVin')}
                  labelPlacement="end"
                />
              </FormGroup>
            )}
            {isDecoding && <LinearProgress />}
          </Grid>

          <Grid item xs={12} md={2.5}>
            <InputTextField label={t('worksheet.mileage')} {...register(`vehicle.mileage`)} error={errors?.mileage} />
          </Grid>

          <Grid item xs={6} md={2.5}>
            <SelectComponent
              items={ECarConditionList.map((item) => ({ label: `worksheet.${item}`, value: item }))}
              label="Condition"
              {...register(`vehicle.condition`)}
              error={errors?.condition}
            />
          </Grid>

          <Grid item xs={6} md={2.5}>
            <MultipleSelectCheckmarks
              title={t('worksheet.includes')}
              content={
                selectedVehicle?.includes.map((item) => ({ label: item as string, value: item as string })) ?? []
              }
              {...register(`vehicle.includes`)}
              defaultValues={selectedVehicle?.includes}
            />
          </Grid>

          <Grid item xs={6} md={2.5}>
            <MultipleSelectCheckmarks
              title={t('worksheet.additions')}
              content={
                selectedVehicle?.additions.map((item) => {
                  const data: SelectValueListItem = { label: item.description, value: item.code }
                  return data
                }) ?? []
              }
              defaultValues={selectedVehicle?.additions.map((x) => x.code)}
              onValueChanged={handleAdditionsChanged}
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <InputTextField
              disabled={!thirdPartySearchOverrideWatch}
              label={t('worksheet.year')}
              {...register(`vehicle.year`)}
              error={errors?.year}
            />
          </Grid>

          <Grid item xs={6} md={2.5}>
            <InputTextField
              disabled={!thirdPartySearchOverrideWatch}
              label={t('worksheet.make')}
              {...register(`vehicle.make`)}
              error={errors?.make}
            />
          </Grid>

          <Grid item xs={6} md={2.5}>
            <InputTextField
              disabled={!thirdPartySearchOverrideWatch}
              label={t('worksheet.model')}
              {...register(`vehicle.model`)}
              error={errors?.model}
            />
          </Grid>

          <Grid item xs={6} md={2.5}>
            <InputTextField
              disabled={!thirdPartySearchOverrideWatch}
              label={t('worksheet.series')}
              {...register(`vehicle.series`)}
              error={errors?.series}
            />
          </Grid>
          <Grid item xs={6} md={2.5}>
            <InputTextField
              disabled={!thirdPartySearchOverrideWatch}
              label={t('worksheet.bodyStyle')}
              {...register(`vehicle.bodyStyle`)}
              error={errors?.bodyStyle}
            />
          </Grid>

          {watch('vehicle.condition') !== ECarCondition.Used && (
            <Grid item xs={12} md={2}>
              <InputTextField
                label={t('worksheet.msrp')}
                {...register(`vehicle.msrp`)}
                error={errors?.msrp}
                disabled={!thirdPartySearchOverrideWatch}
              />
            </Grid>
          )}
        </>
      </Grid>
    </>
  )
}

export default VehicleForm
