import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Link,
  Paper,
  Skeleton,
  Stack,
  SxProps,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material'
import { blue, purple } from '@mui/material/colors'
import { DatePicker } from '@mui/x-date-pickers'
import EnhancedTable, { ColumnDef } from '@src/components/EnhancedTable'
import { AgentLogFilter, useAgentLogDetails, useAgentLogSummaries } from '@src/data/api/report/agentLogReport-api'
import { useAppSelector } from '@src/data/store'
import { userSelectors } from '@src/data/store/UserStore'
import { EFinancingCompany } from '@src/data/types'
import {
  AgentActionLogEntrySDA,
  AgentLogEntryDetail,
  AgentLogEntryStats,
  AgentLogEntrySummary,
  SDAPair,
} from '@src/data/types/AgentLogEntry'
import { AgentLogEntryFilterSchema } from '@src/data/types/AgentLogEntryFilterSchema'
import { formatNumber } from '@src/services/Formatter'
import { endOfDay } from 'date-fns'
import { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Navigate, useSearchParams } from 'react-router-dom'

const defaultValues = AgentLogEntryFilterSchema.getDefault()

type FormFilters = {
  startDate: Date
  endDate: Date
}

type AgentLogReportContainerProps = {
  financingCompanyId: EFinancingCompany
}

const SD_SXPROPS_LT2: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.mode === 'light' ? purple[300] : purple[700],
})
const SD_SXPROPS_LT1: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.mode === 'light' ? purple[100] : purple[300],
})
const SD_SXPROPS_GT1: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.mode === 'light' ? blue[100] : blue[300],
})
const SD_SXPROPS_GT2: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.mode === 'light' ? blue[300] : blue[700],
})

const colorCodeSda = (value: number, sda?: SDAPair): SxProps<Theme> | null => {
  if (!sda) return null
  if (value < sda.average - sda.standardDeviation * 2) return SD_SXPROPS_LT2
  if (value < sda.average - sda.standardDeviation) return SD_SXPROPS_LT1
  if (value > sda.average + sda.standardDeviation) return SD_SXPROPS_GT1
  if (value > sda.average + sda.standardDeviation * 2) return SD_SXPROPS_GT2
  return null
}

const useStatsColumns = (sda?: AgentActionLogEntrySDA | null) => {
  const { t } = useTranslation()
  return useMemo<ColumnDef<AgentLogEntryStats>[]>(
    () => [
      {
        field: 'creditApprovedCount',
        headerName: t('report.agentLog.acceptedCredit'),
        numeric: true,
        align: 'right',
        minWidth: 110,
        conditionalSx: (_row, value: number) => colorCodeSda(value, sda?.creditApprovedSDA),
      },
      {
        field: 'creditDeclinedCount',
        headerName: t('report.agentLog.declinedCredit'),
        numeric: true,
        align: 'right',
        minWidth: 110,
        conditionalSx: (_row, value: number) => colorCodeSda(value, sda?.creditDeclinedSDA),
      },
      {
        field: 'incomeConfirmedCount',
        headerName: t('report.agentLog.incomeConfirmed'),
        numeric: true,
        align: 'right',
        minWidth: 115,
        conditionalSx: (_row, value: number) => colorCodeSda(value, sda?.incomeConfirmedSDA),
      },
      {
        field: 'worksheetApprovedCount',
        headerName: t('report.agentLog.worksheetAccepted'),
        numeric: true,
        align: 'right',
        minWidth: 120,
        conditionalSx: (_row, value: number) => colorCodeSda(value, sda?.worksheetApprovedSDA),
      },
      {
        field: 'worksheetApprovedWithProtectionCount',
        headerName: t('report.agentLog.protectionOffered'),
        numeric: true,
        align: 'right',
        minWidth: 120,
        conditionalSx: (_row, value: number) => colorCodeSda(value, sda?.worksheetApprovedWithProtectionSDA),
      },
      {
        field: 'loanCreatedCount',
        headerName: t('report.agentLog.loanCreated'),
        numeric: true,
        align: 'right',
        minWidth: 100,
        conditionalSx: (_row, value: number) => colorCodeSda(value, sda?.loanCreatedSDA),
      },
      {
        field: 'total',
        headerName: t('common.total'),
        numeric: true,
        align: 'right',
        minWidth: 90,
        valueGetter: (params) =>
          params.creditApprovedCount +
          params.creditDeclinedCount +
          params.incomeConfirmedCount +
          params.worksheetApprovedCount +
          params.worksheetApprovedWithProtectionCount +
          params.loanCreatedCount,
        conditionalSx: (_row, value: number) => colorCodeSda(value, sda?.totalSDA),
      },
      {
        field: 'rateLoweredCount',
        headerName: t('report.agentLog.rateLowered'),
        numeric: true,
        align: 'right',
        minWidth: 120,
        conditionalSx: (_row, value: number) => colorCodeSda(value, sda?.rateLoweredSDA),
      },
      {
        field: 'rateLoweredGE2Count',
        headerName: t('report.agentLog.rateLoweredGE2'),
        numeric: true,
        align: 'right',
        minWidth: 120,
        conditionalSx: (_row, value: number) => colorCodeSda(value, sda?.rateLoweredGE2SDA),
      },
      {
        field: 'rateLoweredTotal',
        headerName: t('common.total'),
        numeric: true,
        align: 'right',
        minWidth: 90,
        valueGetter: (params) => params.rateLoweredCount + params.rateLoweredGE2Count,
        conditionalSx: (_row, value: number) => colorCodeSda(value, sda?.totalRateLoweredSDA),
      },
    ],
    [sda, t],
  )
}

const useSummaryColumns = (sda?: AgentActionLogEntrySDA | null) => {
  const { t } = useTranslation()
  const statsColumns = useStatsColumns(sda)
  return useMemo<ColumnDef<AgentLogEntrySummary>[]>(
    () => [
      {
        field: 'userFullname',
        headerName: t('report.agentLog.agent'),
        sortable: false,
        minWidth: 200,
        width: '20%',
      },
      ...(statsColumns as ColumnDef<AgentLogEntrySummary>[]),
    ],
    [statsColumns, t],
  )
}

const useDetailColumns = () => {
  const { t } = useTranslation()
  const statsColumns = useStatsColumns()
  return useMemo<ColumnDef<AgentLogEntryDetail>[]>(
    () => [
      {
        field: 'referenceNumber',
        headerName: t('report.agentLog.referenceNumber'),
        sortable: false,
        minWidth: 200,
        width: '20%',
        renderCell: (params) => (
          <Link href={`/Applications/${params.financingProgramId}/${params.creditApplicationId}/view`}>
            {params.referenceNumber}
          </Link>
        ),
      },
      ...(statsColumns as ColumnDef<AgentLogEntryDetail>[]),
    ],
    [statsColumns, t],
  )
}

const AgentLogReportContainer = ({ financingCompanyId }: AgentLogReportContainerProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation()
  const defaultFilterValues = useMemo<FormFilters>(() => {
    const startDate = searchParams.get('startDate')
    const endDate = searchParams.get('endDate')
    return {
      startDate: startDate ? new Date(startDate) : defaultValues.startDate,
      endDate: endDate ? new Date(endDate) : defaultValues.endDate,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [formfilters, setFormFilters] = useState<FormFilters>(defaultFilterValues)
  const [selectedUserId, setSelectedUserId] = useState<string | null>(searchParams.get('userId'))
  const detailColumns = useDetailColumns()
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(AgentLogEntryFilterSchema),
    defaultValues: defaultFilterValues,
  })

  const completeFilters = useMemo<AgentLogFilter>(
    () => ({ ...formfilters, financingCompanyId }),
    [financingCompanyId, formfilters],
  )
  const [summaryResult, summaryIsLoading] = useAgentLogSummaries(completeFilters)
  const selectedUserFullname = useMemo(
    () => (selectedUserId ? summaryResult?.list?.find((item) => item.userId === selectedUserId)?.userFullname : null),
    [selectedUserId, summaryResult?.list],
  )
  const summaryColumns = useSummaryColumns(summaryResult?.standardDeviationAveragePairs)

  const [detailList, detailListIsLoading] = useAgentLogDetails(selectedUserId, completeFilters)

  const onSubmit = handleSubmit((data) => {
    setFormFilters({ startDate: data.startDate, endDate: data.endDate })
    setSelectedUserId(null)
    const newSearchParams = new URLSearchParams()
    newSearchParams.set('startDate', data.startDate.toISOString())
    newSearchParams.set('endDate', data.endDate.toISOString())
    if (selectedUserId) newSearchParams.set('userId', selectedUserId)
    setSearchParams(newSearchParams)
  })

  const bottomStaticRow = useMemo(() => {
    if (!summaryResult || !summaryResult.standardDeviationAveragePairs || summaryResult.list.length === 0)
      return undefined
    return (
      <>
        <TableRow>
          <TableCell variant="head" align="right">
            {t('report.agentLog.average')}:
          </TableCell>
          <TableCell align="right">
            {formatNumber(summaryResult.standardDeviationAveragePairs.creditApprovedSDA.average)}
          </TableCell>
          <TableCell align="right">
            {formatNumber(summaryResult.standardDeviationAveragePairs.creditDeclinedSDA.average)}
          </TableCell>
          <TableCell align="right">
            {formatNumber(summaryResult.standardDeviationAveragePairs.incomeConfirmedSDA.average)}
          </TableCell>
          <TableCell align="right">
            {formatNumber(summaryResult.standardDeviationAveragePairs.worksheetApprovedSDA.average)}
          </TableCell>
          <TableCell align="right">
            {formatNumber(summaryResult.standardDeviationAveragePairs.worksheetApprovedWithProtectionSDA.average)}
          </TableCell>
          <TableCell align="right">
            {formatNumber(summaryResult.standardDeviationAveragePairs.loanCreatedSDA.average)}
          </TableCell>
          <TableCell align="right">
            {formatNumber(summaryResult.standardDeviationAveragePairs.totalSDA.average)}
          </TableCell>
          <TableCell align="right">
            {formatNumber(summaryResult.standardDeviationAveragePairs.rateLoweredSDA.average)}
          </TableCell>
          <TableCell align="right">
            {formatNumber(summaryResult.standardDeviationAveragePairs.rateLoweredGE2SDA.average)}
          </TableCell>
          <TableCell align="right">
            {formatNumber(summaryResult.standardDeviationAveragePairs.totalRateLoweredSDA.average)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head" align="right">
            {t('report.agentLog.standardDeviation')}:
          </TableCell>
          <TableCell align="right">
            {formatNumber(summaryResult.standardDeviationAveragePairs.creditApprovedSDA.standardDeviation)}
          </TableCell>
          <TableCell align="right">
            {formatNumber(summaryResult.standardDeviationAveragePairs.creditDeclinedSDA.standardDeviation)}
          </TableCell>
          <TableCell align="right">
            {formatNumber(summaryResult.standardDeviationAveragePairs.incomeConfirmedSDA.standardDeviation)}
          </TableCell>
          <TableCell align="right">
            {formatNumber(summaryResult.standardDeviationAveragePairs.worksheetApprovedSDA.standardDeviation)}
          </TableCell>
          <TableCell align="right">
            {formatNumber(
              summaryResult.standardDeviationAveragePairs.worksheetApprovedWithProtectionSDA.standardDeviation,
            )}
          </TableCell>
          <TableCell align="right">
            {formatNumber(summaryResult.standardDeviationAveragePairs.loanCreatedSDA.standardDeviation)}
          </TableCell>
          <TableCell align="right">
            {formatNumber(summaryResult.standardDeviationAveragePairs.totalSDA.standardDeviation)}
          </TableCell>
          <TableCell align="right">
            {formatNumber(summaryResult.standardDeviationAveragePairs.rateLoweredSDA.standardDeviation)}
          </TableCell>
          <TableCell align="right">
            {formatNumber(summaryResult.standardDeviationAveragePairs.rateLoweredGE2SDA.standardDeviation)}
          </TableCell>
          <TableCell align="right">
            {formatNumber(summaryResult.standardDeviationAveragePairs.totalRateLoweredSDA.standardDeviation)}
          </TableCell>
        </TableRow>
      </>
    )
  }, [summaryResult, t])

  return (
    <>
      <Typography variant="h4">{t('report.agentLog.title')}</Typography>
      <Paper elevation={1}>
        <Toolbar>
          <Stack sx={{ width: '100%' }} gap={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" columnGap={2}>
              <Typography variant="h6">{t('report.agentLog.summaryTitle')}</Typography>
              <Stack direction="row" alignItems="center" gap={1} component="form" onSubmit={onSubmit} noValidate>
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <DatePicker
                      {...field}
                      renderInput={(params) => (
                        <TextField {...params} helperText={error?.message} error={invalid} required />
                      )}
                    />
                  )}
                />
                <Controller
                  name="endDate"
                  control={control}
                  render={({ field: { onChange, ...field }, fieldState: { invalid, error } }) => (
                    <DatePicker
                      {...field}
                      onChange={(date) => onChange(date ? endOfDay(date) : null)}
                      renderInput={(params) => (
                        <TextField {...params} helperText={error?.message} error={invalid} required />
                      )}
                    />
                  )}
                />
                <Button type="submit" variant="contained" color="primary">
                  {t('common.search')}
                </Button>
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center" columnGap={2}>
              <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <span>{t('report.agentLog.legend.sdLt2')}</span>
                <Box
                  component="span"
                  sx={(theme) => ({
                    ...SD_SXPROPS_LT2(theme),
                    height: '1.25em',
                    width: '2.5em',
                    display: 'inline-block',
                  })}
                />
              </Typography>
              <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <span>{t('report.agentLog.legend.sdLt1')}</span>
                <Box
                  component="span"
                  sx={(theme) => ({
                    ...SD_SXPROPS_LT1(theme),
                    height: '1.25em',
                    width: '2.5em',
                    display: 'inline-block',
                  })}
                />
              </Typography>
              <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <span>{t('report.agentLog.legend.sdGt1')}</span>
                <Box
                  component="span"
                  sx={(theme) => ({
                    ...SD_SXPROPS_GT1(theme),
                    height: '1.25em',
                    width: '2.5em',
                    display: 'inline-block',
                  })}
                />
              </Typography>
              <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <span>{t('report.agentLog.legend.sdGt2')}</span>
                <Box
                  component="span"
                  sx={(theme) => ({
                    ...SD_SXPROPS_GT2(theme),
                    height: '1.25em',
                    width: '2.5em',
                    display: 'inline-block',
                  })}
                />
              </Typography>
            </Stack>
          </Stack>
        </Toolbar>
        <EnhancedTable
          rows={summaryResult?.list ?? []}
          columns={summaryColumns}
          getRowId={(r: AgentLogEntrySummary) => r.userId}
          loading={summaryIsLoading}
          onSelection={(newSelection) => {
            setSelectedUserId(newSelection as string)
            setSearchParams((ps) => {
              ps.set('userId', newSelection as string)
              return ps
            })
          }}
          selected={selectedUserId}
          bottomStaticRow={bottomStaticRow}
        />
      </Paper>
      {selectedUserId && (
        <Paper elevation={1} sx={{ mt: 2, mb: 2 }}>
          <Toolbar sx={{ display: 'block' }}>
            <Stack>
              <Typography variant="h6">{t('report.agentLog.detailTitle')}</Typography>
              <Typography variant="subtitle1">
                {selectedUserFullname || <Skeleton variant="text" sx={{ fontSize: '1em', maxWidth: 300 }} />}
              </Typography>
            </Stack>
          </Toolbar>
          <EnhancedTable
            rows={detailList ?? []}
            columns={detailColumns}
            getRowId={(r: AgentLogEntryDetail) => r.creditApplicationId}
            loading={detailListIsLoading}
          />
        </Paper>
      )}
    </>
  )
}

const AgentLogReportPage = () => {
  const user = useAppSelector(userSelectors.getUser)
  const selectedCompany = useAppSelector(userSelectors.selectedCompany)
  if (!user?.rights.canReadManagementReports) {
    return <Navigate to="/notFound" />
  }
  if (!selectedCompany) {
    return null
  }

  return <AgentLogReportContainer financingCompanyId={selectedCompany} />
}

export default AgentLogReportPage
