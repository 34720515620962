import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { getApiClient } from '@src/services/api-client'
import { CreditApplication } from '../../../data/types'
import { ActionsDialog } from '../../../components'
import { Refinancing } from '../../../data/types/RefinancingSchema'
import { reportErrorToConsole } from '../../../services/error-logger'
import LoanDetails from './loanDetails'
import { getLoansApi } from '../../../data/api/refinancing-api'
import { LoanRefinancingOutputDto } from '../../../data/api/credit-application/LoanRefinancingInputDto'
import { creditEffects } from '../../../data/store/CreditApplication'

type Props = {
  creditApplication: CreditApplication
  onCancel: () => void
  open: boolean
  title: string
}

const apiClient = getApiClient()

const RefinancingIcebergDialog = ({ open, creditApplication, onCancel, title }: Props) => {
  const [applyEngagement, setApplyEngagement] = React.useState<boolean>(
    creditApplication.refinancing?.applyEngagement ? creditApplication.refinancing?.applyEngagement : false,
  )
  const [isTotalLoss, setTotalLoss] = React.useState<boolean>(
    creditApplication.refinancing?.isTotalLoss ? creditApplication.refinancing?.isTotalLoss : false,
  )
  const [loans, setLoans] = useState<LoanRefinancingOutputDto[]>([])
  const [selectedLoan, setSelectedLoan] = useState<string>(
    creditApplication.refinancing?.originalLoanId ? creditApplication.refinancing?.originalLoanId : 'noRefinancing',
  )
  const [refinancingEligible, setRefinancingEligible] = React.useState<boolean>(selectedLoan !== 'noRefinancing')
  const dispatchEffect = useSideEffect()

  useEffect(() => {
    if (apiClient)
      getLoansApi
        .loans(apiClient, creditApplication.id)
        .then((data) => {
          setLoans(data)
        })
        .catch(reportErrorToConsole)
  }, [creditApplication.refinancing, creditApplication.id])

  useEffect(() => {
    setTotalLoss(creditApplication.refinancing?.isTotalLoss === true)
    setSelectedLoan(creditApplication.refinancing?.originalLoanId ?? 'noRefinancing')
    setApplyEngagement(creditApplication.refinancing?.applyEngagement === true)
  }, [creditApplication.refinancing])

  const handleClickTotalLoss = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.id === 'non') {
      setTotalLoss(false)
    } else {
      setTotalLoss(true)
    }
  }
  const handleEngagement = () => {
    setApplyEngagement(!applyEngagement)
  }
  const handleSelectedloan = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    if (value === 'noRefinancing') {
      setRefinancingEligible(false)
    } else {
      setRefinancingEligible(true)
    }
    setSelectedLoan(value)
  }

  const { handleSubmit } = useForm<CreditApplication>({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
    defaultValues: creditApplication,
  })

  const handleSaveRefinancing = (data: Refinancing) => {
    return dispatchEffect(creditEffects.setRefinancing(data))
  }

  const onConfirmData = () => {
    const selectedRefinancing = loans.find((x) => x.loanId === selectedLoan)
    const selectedData: Refinancing = {
      versionTag: creditApplication.versionTag!,
      refinancingApplicable: refinancingEligible === true,
      isTotalLoss,
      originalLoanId: refinancingEligible === true && selectedRefinancing ? selectedRefinancing.loanId : '',
      id: creditApplication.id,
      applyEngagement: refinancingEligible === true && selectedRefinancing ? applyEngagement : true,
      amountToRefinance: 0,
      engagementRate: 0,
      engagementAmount: 0,
      creditReportUniqueNumber: '',
      cvtNumber: '',
      vin: '',
      planName: '',
    }
    handleSaveRefinancing(selectedData).catch(reportErrorToConsole)
    onCancel()
  }

  return (
    <ActionsDialog
      onCancel={onCancel}
      onConfirm={handleSubmit(onConfirmData, reportErrorToConsole)}
      open={open}
      title={title}
    >
      <LoanDetails
        loans={loans}
        handleSelectedloan={handleSelectedloan}
        selectedLoan={selectedLoan}
        selectedLoss={isTotalLoss}
        handleClickTotalLoss={handleClickTotalLoss}
        handleEngagement={handleEngagement}
        applyEngagement={applyEngagement}
      />
    </ActionsDialog>
  )
}

export default RefinancingIcebergDialog
