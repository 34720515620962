import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { useCallback } from 'react'
import { getApiClient } from '@src/services/api-client'
import { TRootState, useAppDispatch } from '..'
import { reportErrorToServer } from '../../../services/error-logger'
import { Conflict } from '../../types/Conflict'
import { appActions } from '../AppStore/app-store'
import { userActions } from '../UserStore/user-store'
import type { AsyncEffectHandler, TValidationError } from './side-effects-types'

export function standardApiErrorHandling(
  error: AxiosError<TValidationError>,
  dispatch: ThunkDispatch<TRootState, undefined, AnyAction>,
) {
  const errorDetail = error?.response?.data

  if (!error.response) {
    dispatch(appActions.setTechnicalError(error.message))
    reportErrorToServer(error)
  } else if (error.response.status === 401) {
    dispatch(userActions.setMustAuthenticate(true))
  } else if (error.response.status === 400 && errorDetail?.message) {
    if (errorDetail.type === 'FieldValidations') {
      reportErrorToServer(error)
    }
    dispatch(appActions.setBusinessErrors([{ message: errorDetail.message }]))
  } else if (error.response.status === 409) dispatch(appActions.setConflict(errorDetail as unknown as Conflict))
  else if (error.response.status !== 404) {
    dispatch(appActions.setTechnicalError(JSON.stringify(error.response.data)))
  }
}

const apiClient = getApiClient()
/**
 * Creates a 'dispatch' function used to invoke api (created with the function 'createApi')
 */
export const useSideEffect = () => {
  const dispatch = useAppDispatch()

  const callback = useCallback(
    <T>(apiCall: AsyncEffectHandler<T>) => {
      return apiCall(apiClient, dispatch).catch((ex) => {
        standardApiErrorHandling(ex as AxiosError<TValidationError>, dispatch)
        return Promise.reject(ex)
      })
    },
    [dispatch],
  )
  return callback
}
