/* eslint-disable import/no-duplicates */
import { setDefaultOptions } from 'date-fns'
import type { FormatLongFn, FormatLongWidth, BuildFormatLongFnArgs } from 'date-fns/types'
import { enCA, frCA } from 'date-fns/locale'
import { type ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'

type Props = {
  children: ReactNode
}

// le format de date CA n'est pas défini correctement dans date-fns
const dateFormats = {
  full: 'EEEE d MMMM yyyy',
  long: 'd MMMM yyyy',
  medium: 'd MMM yyyy',
  short: 'yyyy-MM-dd',
}

// Original source function. Import do not work
function buildFormatLongFn<DefaultMatchWidth extends FormatLongWidth>(
  args: BuildFormatLongFnArgs<DefaultMatchWidth>,
): FormatLongFn {
  return (options: { width?: FormatLongWidth } = {}) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const width = options.width ? (String(options.width) as FormatLongWidth) : args.defaultWidth
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const format = args.formats[width] || args.formats[args.defaultWidth]
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return format
  }
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
frCA.formatLong!.date = buildFormatLongFn({
  formats: dateFormats,
  defaultWidth: 'full',
})
/**
 * third party lib, except MUI Core and plugins translation, locale initialisation
 * MUI components translation needs to be set in the theme
 */
const LocaleProvider = ({ children }: Props) => {
  const { i18n } = useTranslation()

  const locale = i18n.resolvedLanguage === 'fr' ? frCA : enCA
  setDefaultOptions({ locale })

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      {children}
    </LocalizationProvider>
  )
}

export default LocaleProvider
