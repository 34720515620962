import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import ClearIcon from '@mui/icons-material/Clear'
import {
  ButtonGroup,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Autocomplete,
  Box,
  Typography,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@mui/material'
import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { startOfDay } from 'date-fns'
import { reportErrorToConsole } from '@src/services/error-logger'
import { userSelectors } from '@src/data/store/UserStore'
import { debounce } from 'lodash-es'
import { getProgramsByCompanyId } from '@src/data/types/FinancingCompanies'
import { getApiClient } from '@src/services/api-client'
import { MultipleSelectCheckmarks, SelectComponent, InputTextField } from '../../components'
import { useAppSelector, TRootState } from '../../data/store'
import { appSelectors } from '../../data/store/AppStore'

import {
  SelectValueListItem,
  Territory,
  Merchant,
  ECreditApplicationStatus,
  EFinancingProgram,
  EProgressionStatusList,
  EFinancingCompany,
  ESourceSystem,
  EIFinanceTeam,
  ELoanPurposeList,
  EProgressionStatus,
} from '../../data/types'
import { TranslatedEnum } from '../../data/types/TranslatedEnum'
import { CreditDashboardFilters } from '../../data/types/CreditDashboardEntry'
import { formatUtcIsoDateTime } from '../../services/Formatter'
import allApis from '../../data/api'

type Props = {
  filterValues: CreditDashboardFilters
  resetFilter: () => void
  applyFilter: (data: CreditDashboardFilters) => void
  addDays: (date: Date, days: number) => Date
  onCancel: () => void
  open: boolean
}

const apiClient = getApiClient()

const SearchFilter = ({
  filterValues,
  resetFilter: resetFilters,
  applyFilter,
  addDays,
  onCancel,
  open,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const selectedCompany = useAppSelector(userSelectors.selectedCompany)
  const territories: Territory[] = useAppSelector(appSelectors.getTerritoryList)

  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum
  const financingProgramEnum = useAppSelector(appSelectors.getFinancingProgramEnum)
  const programsByCompanyId = getProgramsByCompanyId(selectedCompany ? [selectedCompany] : [])
  const programs = useMemo(
    () => programsByCompanyId.map((programId) => appSelectors.getActiveAndInactiveFinancingPrograms(programId)),
    [programsByCompanyId],
  )
  const tags = useAppSelector(appSelectors.getMerchantTags)
  const tagKeys = useAppSelector(appSelectors.getMerchantTagKeys)

  const activeProgramsList = useMemo(() => programs.flatMap((program) => program.activePrograms), [programs])

  const activePrograms = useMemo(
    () => ({
      activePrograms: activeProgramsList,
    }),
    [activeProgramsList],
  )

  const filteredFinancingPrograms = useMemo(
    () =>
      Object.values(financingProgramEnum).filter((program) =>
        activePrograms.activePrograms.includes(program.id as EFinancingProgram),
      ),
    [financingProgramEnum, activePrograms],
  )

  const filteredFinancingProgramIds = useMemo(
    () => filteredFinancingPrograms.map((program) => program.id as EFinancingProgram),
    [filteredFinancingPrograms],
  )

  const allPlans = useAppSelector((state: TRootState) =>
    appSelectors.getAllPlansForPrograms(state, filteredFinancingProgramIds),
  )

  const programPlans = useMemo(() => {
    return allPlans.map((plan) => ({
      label: plan[lang],
      value: plan.id,
    }))
  }, [allPlans, lang])

  const iFinanceTeams: SelectValueListItem[] = useMemo(
    () => [
      {
        label: t('common.all'),
        value: '',
      },
      {
        label: 'iFinance',
        value: EIFinanceTeam.IFinance,
      },
      {
        label: 'Medicard',
        value: EIFinanceTeam.Medicard,
      },
    ],
    [t],
  )

  const creditApplicationStatuses: SelectValueListItem[] = useMemo(
    () => [
      {
        label: t('common.all'),
        value: '',
      },
      {
        label: 'enum.eCreditApplicationStatus.active',
        value: ECreditApplicationStatus.Active,
      },
      {
        label: 'enum.eCreditApplicationStatus.cancelled',
        value: ECreditApplicationStatus.Cancelled,
      },
      {
        label: 'enum.eCreditApplicationStatus.completed',
        value: ECreditApplicationStatus.Completed,
      },
      {
        label: 'enum.eCreditApplicationStatus.draft',
        value: ECreditApplicationStatus.Draft,
      },
      {
        label: 'enum.eCreditApplicationStatus.expired',
        value: ECreditApplicationStatus.Expired,
      },
    ],
    [t],
  )

  const dateRanges: SelectValueListItem[] = useMemo(
    () => [
      {
        label: 'browseCreditApplications.dataGridHeader.lessThanThreeMonths',
        value: `${formatUtcIsoDateTime(startOfDay(addDays(new Date(), -90)))}@${formatUtcIsoDateTime(
          startOfDay(addDays(new Date(), 1)),
        )}`,
      },
      {
        label: 'browseCreditApplications.dataGridHeader.lessThanSixMonths',
        value: `${formatUtcIsoDateTime(startOfDay(addDays(new Date(), -180)))}@${formatUtcIsoDateTime(
          startOfDay(addDays(new Date(), 1)),
        )}`,
      },
      {
        label: 'browseCreditApplications.dataGridHeader.lessThanOneYear',
        value: `${formatUtcIsoDateTime(startOfDay(addDays(new Date(), -365)))}@${formatUtcIsoDateTime(
          startOfDay(addDays(new Date(), 1)),
        )}`,
      },
      {
        label: 'browseCreditApplications.dataGridHeader.lessThanTwoYears',
        value: `${formatUtcIsoDateTime(startOfDay(addDays(new Date(), -730)))}@${formatUtcIsoDateTime(
          startOfDay(addDays(new Date(), 1)),
        )}`,
      },
    ],
    [addDays],
  )
  const [financingProgramIds, setFinancingProgramIds] = React.useState<string[]>(filterValues.financingProgramIds)
  const [loanPurposeIds, setLoanPurposeIds] = React.useState<string[]>(filterValues.loanPurposeIds)
  const [team, setTeam] = React.useState<string>(filterValues.team)
  const [territoryIds, setTerritoryIds] = React.useState<string[]>(filterValues.territoryIds)
  const [progressionStatus, setProgressionStatus] = React.useState<string[]>(filterValues.progressionStatus)
  const [dateRange, setDateRange] = React.useState<string>(filterValues.dateRange)
  const [creditApplicationStatus, setCreditApplicationStatus] = useState<string>(filterValues.creditApplicationStatus)
  const [plan, setPlan] = useState<string[]>(filterValues.plan)
  const [merchantId, setMerchantId] = useState<string>(filterValues.merchantId)
  const [tag, setTag] = useState<string | null>(filterValues.tag || null)
  const MERCHANTS_LIMIT = 25
  const [merchants, setMerchants] = useState<Merchant[]>([])
  const [merchant, setMerchant] = useState<Merchant | null>(null)
  const [loadingMerchant, setLoadingMerchant] = useState<boolean>(false)
  const [query, setQuery] = useState('')

  const filteredTerritories = useMemo(() => {
    const sourceSystem = selectedCompany === EFinancingCompany.Iceberg ? ESourceSystem.Monolith : ESourceSystem.Alis
    const filteredTerritoriesBySourceSystem = territories.filter((territory) => territory.sourceSystem === sourceSystem)
    return filteredTerritoriesBySourceSystem
  }, [territories, selectedCompany])

  const updateQuery = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setQuery(e.target.value)
  const debounceOnChange = debounce(updateQuery, 1000)

  const shouldQueryForAllCreditApplicationsStatus = (status: string[]) =>
    status.some((value) =>
      [
        EProgressionStatus.Quotation,
        EProgressionStatus.Submitted,
        EProgressionStatus.Booked,
        EProgressionStatus.WithDrawn,
      ].includes(value as EProgressionStatus),
    )

  const onChangeProgressionStatus = (status: string[]) => {
    if (shouldQueryForAllCreditApplicationsStatus(status)) setCreditApplicationStatus('')
    setProgressionStatus(status)
  }

  useEffect(() => {
    if (merchantId && apiClient) {
      setLoadingMerchant(true)
      allApis.config
        .getMerchantById(apiClient, { id: merchantId })
        .then((result) => {
          setMerchant(result)
          setLoadingMerchant(false)
        })
        .catch(reportErrorToConsole)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (query && apiClient) {
      const dto = {
        financingCompanyId: selectedCompany,
        nameContains: query,
        limit: MERCHANTS_LIMIT,
      }
      setLoadingMerchant(true)
      allApis.config
        .getMerchants(apiClient, dto)
        .then((result) => {
          setMerchants(result)
          setLoadingMerchant(false)
        })
        .catch(reportErrorToConsole)
    }
  }, [query, selectedCompany])

  useEffect(() => {
    setFinancingProgramIds(filterValues.financingProgramIds)
    setTeam(filterValues.team)
    setTerritoryIds(filterValues.territoryIds)
    setLoanPurposeIds(filterValues.loanPurposeIds)
    setDateRange(filterValues.dateRange === '' ? dateRanges[0].value.toString() : filterValues.dateRange)
    setCreditApplicationStatus(
      filterValues.creditApplicationStatus ? filterValues.creditApplicationStatus : ECreditApplicationStatus.Active,
    )
    setPlan(filterValues.plan)
    setMerchantId(filterValues.merchantId)
    setTag(filterValues.tag || null)
  }, [filterValues, dateRanges])

  const onApplyFilters = () => {
    applyFilter({
      ...filterValues,
      financingProgramIds,
      loanPurposeIds,
      team,
      dateRange,
      territoryIds,
      creditApplicationStatus,
      progressionStatus,
      plan,
      merchantId,
      tag,
    })
    onCancel()
  }

  return (
    <Dialog
      onClose={onCancel}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
    >
      <DialogTitle id="alert-dialog-title">{t('common.filter')}</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="merchantList"
              options={merchants}
              onChange={(_event, selectedMerchant: Merchant | null) => {
                if (selectedMerchant) {
                  setMerchantId(selectedMerchant.id)
                  setMerchant(selectedMerchant)
                }
              }}
              value={merchant}
              autoHighlight
              getOptionKey={(option) => option.id}
              getOptionLabel={(option) => option.name}
              noOptionsText={t('common.searchYieldsNoResults')}
              renderInput={(merchantList) => (
                <InputTextField
                  onChange={(e) => {
                    debounceOnChange(e)
                  }}
                  {...merchantList}
                  label={t('common.merchant')}
                  InputProps={{
                    ...merchantList.InputProps,
                    endAdornment: loadingMerchant ? <CircularProgress color="inherit" size={20} /> : null,
                  }}
                />
              )}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  {...props}
                  sx={{
                    color: option.isActive ? 'unset' : 'grey',
                    opacity: option.isActive ? 1 : 0.9,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start !important',
                    textAlign: 'left !important',
                  }}
                >
                  <Typography variant="body1">{option.name}</Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.80rem', color: '#808080' }}>
                    {option.address}, {option.city}, {option.province}
                  </Typography>
                </Box>
              )}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MultipleSelectCheckmarks
              title={t('credit.plan')}
              content={programPlans}
              defaultValues={plan}
              onValueChanged={(planValue: number[] | string[]) => {
                setPlan(planValue as string[])
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MultipleSelectCheckmarks
              title={t('browseCreditApplications.dataGridHeader.territory')}
              content={filteredTerritories.map((item) => {
                return { label: item.name, value: item.id } as SelectValueListItem
              })}
              defaultValues={territoryIds}
              onValueChanged={(territoryId: number[] | string[]) => {
                setTerritoryIds(territoryId as string[])
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MultipleSelectCheckmarks
              title={t('editCreditApplication.financingProgramType')}
              content={Object.values(filteredFinancingPrograms).map((financingProgram) => {
                const data: SelectValueListItem = {
                  label: financingProgram[lang],
                  value: financingProgram.id,
                }
                return data
              })}
              defaultValues={financingProgramIds}
              onValueChanged={(financingProgram: number[] | string[]) => {
                setFinancingProgramIds(financingProgram as string[])
              }}
            />
          </Grid>
          {selectedCompany === EFinancingCompany.IFinance && (
            <Grid item xs={12} md={6}>
              <MultipleSelectCheckmarks
                title={t('editCreditApplication.loanPurpose')}
                content={ELoanPurposeList.map<SelectValueListItem>((item) => {
                  return { label: t(`enum.eLoanPurposes.${item}`), value: item }
                })}
                defaultValues={loanPurposeIds}
                onValueChanged={(loanPurposes: number[] | string[]) => {
                  setLoanPurposeIds(loanPurposes as string[])
                }}
              />
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <SelectComponent
              items={dateRanges}
              label={t('common.created') as string}
              onChange={(e: { target: { value: React.SetStateAction<string> } }) => setDateRange(e.target.value)}
              value={dateRange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectComponent
              items={creditApplicationStatuses}
              label={t('browseCreditApplications.dataGridHeader.creditApplicationStatus') as string}
              onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                setCreditApplicationStatus(e.target.value)
              }
              value={creditApplicationStatus}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MultipleSelectCheckmarks
              title={t('credit.progressionStatus')}
              content={EProgressionStatusList.map((item) => ({
                label: t(`enum.eProgressionStatus.${item}`),
                value: item,
              }))}
              defaultValues={progressionStatus}
              onValueChanged={(status: number[] | string[]) => {
                onChangeProgressionStatus(status as string[])
              }}
            />
          </Grid>
          {selectedCompany === EFinancingCompany.IFinance && (
            <Grid item xs={12} md={6}>
              <SelectComponent
                items={iFinanceTeams}
                label={t('common.team') as string}
                onChange={(e: { target: { value: React.SetStateAction<string> } }) => setTeam(e.target.value)}
                value={team}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Autocomplete
              options={tagKeys}
              getOptionLabel={(opt) => tags.find((item) => item.id === opt)!.text}
              value={tag}
              onChange={(_e, value) => setTag(value)}
              renderInput={(params) => <InputTextField {...params} label={t('common.tags')} />}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup>
          <Button
            variant="outlined"
            startIcon={<ClearIcon fontSize="small" color="error" />}
            onClick={() => {
              resetFilters()
              onCancel()
            }}
          >
            {t('taskManager.reset')}
          </Button>
          <Button
            variant="outlined"
            startIcon={<KeyboardArrowRightIcon fontSize="small" color="success" />}
            onClick={onApplyFilters}
          >
            {t('taskManager.apply')}
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  )
}
export default React.memo(SearchFilter)
