import React from 'react'
import { t } from 'i18next'
import WarningMessageList from './warningMessageList'
import { NormsMessage, NormsMessageKey, ValueListItem } from '../../../data/types'
import { useAppSelector } from '../../../data/store'
import { appSelectors } from '../../../data/store/AppStore'

type Props = {
  messageTitle: string
  creditWarningMessages: NormsMessage[] | null
  size: string | null
}

const CreditWarningMessageList = ({ creditWarningMessages, messageTitle, size }: Props) => {
  const creditRefusalReasons = useAppSelector<ValueListItem[]>(appSelectors.getCreditRefusalReasons)
  const handleReasonsToDisplay = (x: NormsMessage) => {
    const reason = creditRefusalReasons.find((r) => r.id === x.id)

    return reason?.text ?? t(`CreditDecisionNorms.${x.message as NormsMessageKey}`)
  }

  const creditWarnings: NormsMessage[] =
    creditWarningMessages?.map((x) => ({ ...x, message: handleReasonsToDisplay(x) })) ?? []
  return <WarningMessageList messageTitle={messageTitle} normsMessages={creditWarnings} size={size} />
}

export default CreditWarningMessageList
