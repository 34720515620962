import { ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import Button from '@mui/material/Button'
import ClearIcon from '@mui/icons-material/Clear'
import { useAppSelector } from '@src/data/store'
import { getProgramsByCompanyId } from '@src/data/types/FinancingCompanies'
import { buildSelectValueListFromEnumWithTranslation } from '@src/components/buildSelectValueList'
import { userSelectors } from '@src/data/store/UserStore'
import { appSelectors } from '@src/data/store/AppStore'
import { TranslatedEnum } from '@src/data/types/TranslatedEnum'
import { formatUtcIsoDateTime } from '@src/services/Formatter'
import { addDays, endOfDay, startOfDay } from 'date-fns'
import {
  CreditTaskDashboardFilters,
  EAssignmentFilterList,
  EEntryTypeList,
  EFinancingCompany,
  ELanguageList,
  EProvinceList,
  SelectValueListItem,
} from '../../../data/types'
import { MultipleSelectCheckmarks, SelectComponent } from '../../../components'

type Props = {
  filterValues: CreditTaskDashboardFilters
  resetFilter: () => void
  applyFilter: (data: CreditTaskDashboardFilters) => void
  trusteeNames: string[]
  open: boolean
  onCancel: () => void
}

const FilterComponent = ({ filterValues, open, resetFilter, onCancel, applyFilter, trusteeNames }: Props) => {
  const [selectedLoanTypes, setSelectedLoanTypes] = React.useState<string[]>(filterValues.financingProgramIds)
  const [selectedEntryTypes, setSelectedEntryTypes] = React.useState<string[]>(filterValues.entryType)
  const [selectedLanguageId, setSelectedLanguageId] = React.useState<number[]>(filterValues.correspondanceLanguageId)
  const [selectedAssignmentStatus, setselectedAssignmentStatus] = React.useState<string>(filterValues.assignation)
  const [selectedBankruptcyTrusteeName, setSelectedBankruptcyTrusteeName] = React.useState<string>(
    filterValues.trusteeName,
  )
  const [selectedStatesIso, setSelectedStatesIso] = React.useState<string[]>(filterValues.statesIso)

  const { t } = useTranslation()
  const selectedCompany = useAppSelector(userSelectors.selectedCompany)
  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum
  const financingProgramEnum = useAppSelector(appSelectors.getFinancingProgramEnum)
  const programsByCompanyId = getProgramsByCompanyId([selectedCompany] as EFinancingCompany[])
  const availableFinancingPrograms = buildSelectValueListFromEnumWithTranslation(
    financingProgramEnum,
    lang,
    programsByCompanyId,
    undefined,
    ['ifxpress', 'vlvr'],
  )
  const [dateRange, setSelectedDateRange] = React.useState<string>(filterValues.dateRange)

  const reset = () => {
    resetFilter()
  }

  const dateRanges: SelectValueListItem[] = useMemo(
    () => [
      {
        label: 'taskManager.tasksForOption.now',
        value: `@${formatUtcIsoDateTime(new Date())}`,
      },
      {
        label: 'taskManager.tasksForOption.tomorrow',
        value: `${formatUtcIsoDateTime(startOfDay(addDays(new Date(), 1)))}@${formatUtcIsoDateTime(
          endOfDay(addDays(new Date(), 1)),
        )}`,
      },
      {
        label: 'taskManager.tasksForOption.inTwoDays',
        value: `${formatUtcIsoDateTime(startOfDay(addDays(new Date(), 2)))}@${formatUtcIsoDateTime(
          endOfDay(addDays(new Date(), 2)),
        )}`,
      },
    ],
    [],
  )

  useEffect(() => {
    setSelectedLoanTypes(filterValues.financingProgramIds)
    setSelectedEntryTypes(filterValues.entryType)
    setSelectedLanguageId(filterValues.correspondanceLanguageId)
    setselectedAssignmentStatus(filterValues.assignation)
    setSelectedBankruptcyTrusteeName(filterValues.trusteeName)
    setSelectedStatesIso(filterValues.statesIso)
    setSelectedDateRange(filterValues.dateRange)
  }, [filterValues])

  return (
    <Dialog
      onClose={onCancel}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
    >
      <DialogTitle id="alert-dialog-title">{t('common.filter')}</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <MultipleSelectCheckmarks
              title={t('editCreditApplication.financingProgramType')}
              content={availableFinancingPrograms}
              defaultValues={selectedLoanTypes}
              onValueChanged={(financingProgram: number[] | string[]) => {
                setSelectedLoanTypes(financingProgram as string[])
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MultipleSelectCheckmarks
              title={t('taskManager.taskType')}
              content={EEntryTypeList.map((value) => ({ label: t(`taskManager.${value}`), value }))}
              defaultValues={selectedEntryTypes}
              onValueChanged={(entryType: number[] | string[]) => {
                setSelectedEntryTypes(entryType as string[])
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <SelectComponent
              items={EAssignmentFilterList.map((value) => ({
                label: t(`enum.eAssignmentFilter.${value}`),
                value,
              }))}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setselectedAssignmentStatus(event.target.value)
              }}
              label={t('taskManager.applicationAssignment') as string}
              value={selectedAssignmentStatus}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <SelectComponent
              items={Object.values(trusteeNames).map((value) => ({
                label: value,
                value,
              }))}
              onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                setSelectedBankruptcyTrusteeName(e.target.value)
              }
              value={selectedBankruptcyTrusteeName}
              label={t('taskManager.bankruptcyTrusteeNames') as string}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MultipleSelectCheckmarks
              title={t('credit.languageId')}
              content={ELanguageList.map((value) => ({
                label: t(`enum.eLanguage.${value}`),
                value,
              }))}
              defaultValues={selectedLanguageId}
              onValueChanged={(languageIds: number[] | string[]) => {
                setSelectedLanguageId(languageIds as number[])
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MultipleSelectCheckmarks
              content={EProvinceList.map((value) => ({ label: value, value }))}
              onValueChanged={(e: string[] | number[]) => setSelectedStatesIso(e as string[])}
              defaultValues={selectedStatesIso}
              title="Province"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectComponent
              items={dateRanges}
              label={t('taskManager.tasksFor') as string}
              onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                setSelectedDateRange(e.target.value)
              }
              value={dateRange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup>
          <Button
            variant="outlined"
            startIcon={<ClearIcon fontSize="small" color="error" />}
            onClick={() => {
              onCancel()
              reset()
            }}
          >
            {t('taskManager.reset')}
          </Button>
          <Button
            variant="outlined"
            startIcon={<KeyboardArrowRightIcon fontSize="small" color="success" />}
            onClick={() => {
              applyFilter({
                ...filterValues,
                offset: 0,
                financingProgramIds: selectedLoanTypes,
                entryType: selectedEntryTypes,
                correspondanceLanguageId: selectedLanguageId,
                assignation: selectedAssignmentStatus,
                trusteeName: selectedBankruptcyTrusteeName,
                statesIso: selectedStatesIso,
                dateRange,
              })
              onCancel()
            }}
          >
            {t('taskManager.apply')}
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  )
}

export default FilterComponent
