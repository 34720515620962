import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { reportErrorToConsole } from '@src/services/error-logger'
import { getApiClient } from '@src/services/api-client'
import { useAppSelector } from '../../data/store'
import { creditSelectors } from '../../data/store/CreditApplication'
import { messageSelectors, messageEffects } from '../../data/store/Message'
import MessageList from './components/MessageList'
import { CreditApplicationMessage, Merchant } from '../../data/types'
import MessageHeader from './components/MessageHeader'
import apis from '../../data/api'

const apiClient = getApiClient()

const CreditApplicationMessagePage = (): JSX.Element | null => {
  const { id } = useParams()

  const dispatchEffect = useSideEffect()

  const [merchant, setMerchant] = useState<Merchant | null>(null)
  const currentApplication = useAppSelector(creditSelectors.getCurrent)
  const messages = useAppSelector(messageSelectors.getMessageList)

  useEffect(() => {
    if (currentApplication) {
      document.title = `${currentApplication.applicant.firstName} ${currentApplication.applicant.lastName}`
    }
  }, [currentApplication])

  useEffect(() => {
    if (currentApplication?.merchantId && apiClient) {
      apis.config
        .getMerchantById(apiClient, { id: currentApplication.merchantId })
        .then(setMerchant)
        .catch(reportErrorToConsole)
    }
  }, [currentApplication?.merchantId])

  const onSave = (data: Partial<CreditApplicationMessage>) => {
    data.creditApplicationId = id
    data.financingProgramId = currentApplication?.financingProgramId
    return dispatchEffect(messageEffects.createMessage(data))
  }
  return (
    <div style={{ marginBottom: 25 }}>
      <Grid container item xs={12} spacing={2} alignItems="center">
        {currentApplication && merchant && (
          <MessageHeader merchant={merchant} currentApplication={currentApplication} />
        )}
        <Grid item xs={12}>
          <MessageList messages={messages} onSave={onSave} />
        </Grid>
      </Grid>
    </div>
  )
}

export default CreditApplicationMessagePage
