import { Grid, Stack, useTheme } from '@mui/material'
import { nanoid } from 'nanoid'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BaseWorksheetFundingThresholdExtend,
  CreditApplicationMessage,
  EFinancingProgram,
  EMerchantIds,
  EPlanType,
  EProvince,
  EWorksheetDecision,
  Merchant,
  NormsMessage,
  PersonalWorksheetDecisionDTO,
  WorksheetDecision,
} from '@src/data/types'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { TRootState, useAppDispatch, useAppSelector } from '@src/data/store'
import { appSelectors } from '@src/data/store/AppStore'
import { creditSelectors } from '@src/data/store/CreditApplication'
import { messageEffects, messageSelectors } from '@src/data/store/Message'
import { availableCreditSelectors } from '@src/data/store/AvailableCredit/available-credit-store'
import { documentSelectors } from '@src/data/store/Document'
import { reportErrorToConsole } from '@src/services/error-logger'
import { FundingInfoDto } from '@src/data/types/FundingInfoDto'
import { personalLoanWorksheetEffects } from '@src/data/store/PersonalLoanWorksheet'
import { fullCreditApplicationEffects } from '@src/data/store/FullCreditApplication'
import PendingMessage from '@src/components/PendingMessage'
import { Breadcrumb, InputTextField, PageError, PageSpinner } from '@src/components'
import WarningMessageAccordion from '@src/containers/ViewCreditApplicationPage/components/warningMessageAccordion'
import PrivateMessage from '@src/containers/ViewCreditApplicationPage/components/privateMessage'
import { getApiClient } from '@src/services/api-client'
import AvailableCreditTooltip from '@src/components/AvailableCreditTooltip'
import { ErrorOutline } from '@mui/icons-material'
import allApis from '../../../data/api'
import { userSelectors } from '../../../data/store/UserStore'
import {
  personalLoanWorksheetActions,
  personalLoanWorksheetSelectors,
} from '../../../data/store/PersonalLoanWorksheet/personal-loan-worksheet-store'
import { useDialogDecisionDefaultValue } from '../viewWorksheet-hooks'
import { canComputeFunding, getEmptyComputedFundingDto } from '../viewWorksheet-selectors'
import LoanSummaryBox from '../components/LoanSummaryBox'
import WorksheetDecisionSection from '../components/WorksheetDecisionSection'
import TransactionSection from '../components/TransactionSection'
import WorksheetDecisionDialog from '../components/WorksheetDecisionDialog'

const PAY_CUSTOMER_IDS = [EMerchantIds.PayCustomerByBankTransfer, EMerchantIds.PayCustomerByCheque] as string[]
const apiClient = getApiClient()

const PersonalLoanWorksheetViewPage = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  const [currentOpenDialog, setCurrentOpenDialog] = React.useState<string>('')

  const dispatchEffect = useSideEffect()

  const dispatch = useAppDispatch()
  const isLoading = useAppSelector(creditSelectors.isLoadingCreditApplication)
  const creditApplication = useAppSelector(creditSelectors.getCurrent)
  const error = useAppSelector(appSelectors.getBusinessError)
  const privateMessages = useAppSelector(messageSelectors.getPrivateMessages)
  const commonNormsMessage: NormsMessage[] = useAppSelector(creditSelectors.getCurrentCreditApplicationSpecificNorms)
  const personalLoanWorksheet = useAppSelector(personalLoanWorksheetSelectors.getCurrent)
  const computedInfo = useAppSelector(personalLoanWorksheetSelectors.getComputedInfo)
  const activePaymentPlan = useAppSelector(creditSelectors.getMerchantPaymentPlan)
  const financingConfig = useAppSelector((state: TRootState) =>
    appSelectors.getFinancingConfig(state, creditApplication?.financingProgramId),
  )

  const [paymentMerchantsByIds, setPaymentMerchantsByIds] = useState<Record<string, Merchant>>({})
  const [isLoadingMerchants, setIsLoadingMerchants] = useState(true)

  const availableCreditData = useAppSelector(availableCreditSelectors.get)
  const availableCredit = useAppSelector(availableCreditSelectors.getAvailableCredit)
  const creditInProgress = useAppSelector(availableCreditSelectors.getCreditInProgress)

  const totalObligation = useAppSelector(personalLoanWorksheetSelectors.getTotalObligation)
  const user = useAppSelector(userSelectors.getUser)
  const worksheetNormWarningMessages = useAppSelector(
    personalLoanWorksheetSelectors.getCurrentWorksheetNormWarningMessages,
  )

  const areAllTasksRequiredForCVTCompleted = useAppSelector(creditSelectors.areAllTasksRequiredForCVTCompleted)
  const areAllDocumentsRequiredForCVTApproved = useAppSelector(documentSelectors.areAllDocumentsRequiredForCVT)
  const confirmAllIncomesStatus = useAppSelector(creditSelectors.getConfirmAllIncomesStatus)
  const finalDecisionIsPending = useAppSelector(creditSelectors.isFinalDecisionPending)

  const dialogDecisionDefaultValue = useDialogDecisionDefaultValue(
    creditApplication!,
    personalLoanWorksheet!,
    areAllTasksRequiredForCVTCompleted,
    areAllDocumentsRequiredForCVTApproved,
    confirmAllIncomesStatus!,
  )

  const editDisabled = creditApplication?.editLocked === true || finalDecisionIsPending
  const planName = creditApplication?.normsCreditDecision?.planName ?? EPlanType.default

  const breadCrumbs = useMemo(
    () => [
      { path: '/', label: t('breadcrumbs.home') },
      { path: '/Applications/browse', label: t('breadcrumbs.creditApplication') },
      {
        path: `/Applications/${EFinancingProgram.Personal}/${creditApplication?.id}/view`,
        label: t('breadcrumbs.application').concat(` #${creditApplication?.referenceNumber}`),
      },
      { path: '#', label: t('worksheet.financing') },
    ],
    [creditApplication, t],
  )

  const EditDecisionDialogOpen = 'EditDecisionDialogOpen'

  useEffect(() => {
    if (personalLoanWorksheet) {
      const merchantIdsToFetch = personalLoanWorksheet.merchantPayments
        .filter((p) => PAY_CUSTOMER_IDS.indexOf(p.merchantId) === -1)
        .map((p) => p.merchantId)

      const newPaymentMechantsByIds = {} as Record<string, Merchant>
      if (merchantIdsToFetch.length > 0 && apiClient) {
        const promises = merchantIdsToFetch.map((id) => allApis.config.getMerchantById(apiClient, { id }))
        Promise.all(promises)
          .then((results) => {
            results.forEach((m) => {
              newPaymentMechantsByIds[m.id] = m
            })
            setPaymentMerchantsByIds(newPaymentMechantsByIds)
            setIsLoadingMerchants(false)
          })
          .catch(reportErrorToConsole)
      } else {
        setIsLoadingMerchants(false)
      }
    }
  }, [personalLoanWorksheet])

  React.useEffect(() => {
    if (creditApplication && personalLoanWorksheet && Object.keys(computedInfo).length === 0) {
      const interestRate = creditApplication.finalCreditDecision.interestRate ?? 0
      const hasCoApplicant = creditApplication.coApplicant !== null
      const stateIso = creditApplication?.applicant.currentAddress.stateIso
      const requestId = nanoid()

      const computeDto: FundingInfoDto = {
        financingProgramId: creditApplication.financingProgramId,
        amountRequested: personalLoanWorksheet.amountRequested ?? 0,
        includeInsurance: personalLoanWorksheet.includeInsurance,
        paymentFrequency: personalLoanWorksheet.paymentFrequency,
        paymentPlanId: personalLoanWorksheet.paymentPlanId,
        term: personalLoanWorksheet.term,
        interestRate,
        hasCoApplicant,
        stateIso: stateIso as EProvince,
        deliveryOn: personalLoanWorksheet.deliveryOn,
        firstPaymentOn: personalLoanWorksheet.firstPaymentOn,
        requestId,
        creditApplicationId: creditApplication.id,
        merchantId: creditApplication.merchantId,
        insuranceCompanyId: personalLoanWorksheet.insuranceCompanyId,
      }

      if (canComputeFunding(computeDto, financingConfig.minimumLoanAmount)) {
        dispatchEffect(personalLoanWorksheetEffects.computePersonalLoanFunding(computeDto)).catch(reportErrorToConsole)
      } else {
        const emptyComputedDto = getEmptyComputedFundingDto()

        dispatch(personalLoanWorksheetActions.setFundingComputed(emptyComputedDto))
      }
    }
  }, [
    personalLoanWorksheet,
    computedInfo,
    creditApplication,
    dispatchEffect,
    dispatch,
    financingConfig.minimumLoanAmount,
  ])

  // callbacks
  const closeDialogs = useCallback(() => {
    setCurrentOpenDialog('')
  }, [])

  const handleSaveDecision = useCallback(
    async (data: WorksheetDecision) => {
      if (personalLoanWorksheet) {
        const updatedFinalDecision: PersonalWorksheetDecisionDTO = {
          creditApplicationId: personalLoanWorksheet.creditApplicationId,
          decision: data.decision,
          otherReason: data.otherReason,
          versionTag: personalLoanWorksheet.versionTag,
        } as PersonalWorksheetDecisionDTO

        if (data.decision === EWorksheetDecision.ApprovedWithCondition) {
          const fundingThresholdExtend: BaseWorksheetFundingThresholdExtend = {
            maxPmtAmount: data.maxPmtAmount,
            maxTotalAmountFinanced: data.maxTotalAmountFinanced,
            maxTermDuration: data.maxTermDuration,
          }
          updatedFinalDecision.fundingThresholdExtend = fundingThresholdExtend
        }

        await dispatchEffect(
          personalLoanWorksheetEffects.updateDecision({
            ...updatedFinalDecision,
            creditApplicationId: creditApplication?.id,
          }),
        ).then(async () => {
          await dispatchEffect(fullCreditApplicationEffects.getById(creditApplication!.id, EFinancingProgram.Personal))
          closeDialogs()
        })
      }
    },
    [personalLoanWorksheet, creditApplication, closeDialogs, dispatchEffect],
  )

  const handleDeletingPrivateMessage = async (message: CreditApplicationMessage) => {
    if (creditApplication?.id) {
      await dispatchEffect(messageEffects.deleteMessage(message, creditApplication.financingProgramId))
    }
  }

  return (
    <div>
      <PageSpinner isLoading={isLoading} />
      {!isLoading && personalLoanWorksheet && creditApplication && (
        <>
          <Breadcrumb trees={breadCrumbs} />
          <Stack>
            <PageError errors={error} />
            {finalDecisionIsPending && <PendingMessage />}
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <InputTextField value={t(`credit.plans.${planName}`)} label={t('worksheetCommon.program')} disabled />
              <InputTextField
                label={t('worksheetCommon.creditLimit')}
                disabled
                value={creditApplication?.finalCreditDecision?.maxAmountFinanced ?? 0}
              />
              <AvailableCreditTooltip availableCreditData={availableCreditData}>
                <div style={{ position: 'relative' }}>
                  <InputTextField label={t('worksheetCommon.creditInProgress')} disabled value={creditInProgress} />
                  {creditInProgress !== 0 && (
                    <ErrorOutline
                      sx={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        color: theme.palette.error.main,
                        fontSize: '1.5rem',
                      }}
                    />
                  )}
                </div>
              </AvailableCreditTooltip>

              <InputTextField label={t('worksheetCommon.availableCredit')} disabled value={availableCredit} />
            </Stack>
            {commonNormsMessage?.length > 0 && (
              <WarningMessageAccordion
                applicantCreditWarnings={null}
                coapplicantCreditWarnings={null}
                commonCreditWarnings={commonNormsMessage}
                worksheetWarnings={null}
                expanded={false}
              />
            )}

            <PrivateMessage
              messages={privateMessages}
              handleDeletingPrivateMessage={handleDeletingPrivateMessage}
              currentUser={user}
              creditAppCreationDate={creditApplication.createdOn}
            />
            <Grid container textAlign="center" justifyContent="space-around" p={4}>
              <Grid item xs={12} md={4} border="1px solid" borderRadius={2} p={4}>
                <LoanSummaryBox
                  worksheet={personalLoanWorksheet}
                  computedInfo={computedInfo}
                  merchantPaymentPlan={activePaymentPlan ?? null}
                />
              </Grid>
              <Grid item xs={12} md={4} border="1px solid" borderRadius={2} p={4}>
                <WorksheetDecisionSection
                  worksheet={personalLoanWorksheet}
                  onClick={() => setCurrentOpenDialog(EditDecisionDialogOpen)}
                  editDisabled={editDisabled}
                  userCanEditWorksheet={user?.rights.canEditWorksheet === true}
                />
              </Grid>
            </Grid>
            {!isLoadingMerchants && (
              <TransactionSection
                worksheet={personalLoanWorksheet}
                creditApplication={creditApplication}
                computedInfo={computedInfo}
                paymentMerchantsByIds={paymentMerchantsByIds}
                totalObligation={totalObligation}
                merchantPaymentPlan={activePaymentPlan ?? null}
                productsPlans={[]}
              />
            )}
          </Stack>

          <WorksheetDecisionDialog
            open={currentOpenDialog === EditDecisionDialogOpen}
            title={t('worksheet.decision')}
            onCancel={closeDialogs}
            onConfirm={handleSaveDecision}
            worksheetWarnings={worksheetNormWarningMessages}
            defaultValue={dialogDecisionDefaultValue}
            worksheet={personalLoanWorksheet}
            creditapplication={creditApplication}
          />
        </>
      )}
    </div>
  )
}

export default PersonalLoanWorksheetViewPage
