import React, { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  Alert,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { debounce } from 'lodash-es'
import { reportErrorToConsole } from '@src/services/error-logger'
import { getApiClient } from '@src/services/api-client'
import ActionsDialog from './ActionsDialog'
import InputTextField from './InputTextField'
import { EFinancingCompany, EFinancingProgram, Merchant } from '../data/types'
import allApis from '../data/api'

type OnSelectSingleMerchant = (merchant: Merchant) => void
type OnSelectMultipleMerchants = (merchants: Merchant[]) => void

type Props = {
  open: boolean
  title: string
  label: string
  allowMultiple: boolean
  financingProgramId: EFinancingProgram | null
  financingCompanyId: EFinancingCompany | null
  onConfirm: OnSelectSingleMerchant | OnSelectMultipleMerchants
  onCancel: () => void
  disableConfirm?: boolean
}

const apiClient = getApiClient()

const SelectMerchantDialog = ({
  onConfirm,
  onCancel,
  open,
  title,
  label,
  financingProgramId,
  financingCompanyId,
  allowMultiple,
  disableConfirm,
}: Props) => {
  const MERCHANTS_LIMIT = 25
  const { t } = useTranslation()
  const [query, setQuery] = useState('')
  const [merchants, setMerchants] = useState<Merchant[]>([])
  const [selectedMerchants, setSelectedMerchants] = useState<Merchant[]>([])

  const updateQuery = (e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)
  const debounceOnChange = debounce(updateQuery, 1000)

  React.useEffect(() => {
    if (query && query.trim() && apiClient) {
      const dto = {
        financingProgramId,
        financingCompanyId,
        nameContains: query,
        limit: MERCHANTS_LIMIT,
        isActive: true,
      }
      allApis.config
        .getMerchants(apiClient, dto)
        .then((result) => setMerchants(result))
        .catch(reportErrorToConsole)
    } else {
      setMerchants([])
      if (!allowMultiple) setSelectedMerchants([])
    }
  }, [query, allowMultiple, onConfirm, financingProgramId, financingCompanyId])

  const handleItemSelected = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const itemValue = (evt as React.MouseEvent<HTMLButtonElement>).currentTarget.id
      const value = merchants.find((x) => x.id === itemValue)
      if (value) {
        setSelectedMerchants([value])
      }
    },
    [merchants],
  )

  const handleItemChecked = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const itemValue = evt.target.value
      const selectedMerchant = merchants.find((x) => x.id === itemValue)
      if (selectedMerchant) {
        setSelectedMerchants((prev) => {
          const existingIndex = prev.findIndex((x) => x.id === selectedMerchant.id)
          if (existingIndex !== -1) {
            return prev.filter((x) => x.id !== selectedMerchant.id)
          }
          return [...prev, selectedMerchant]
        })
      }
    },
    [merchants],
  )

  const cancel = useCallback(() => {
    setQuery('')
    onCancel()
  }, [onCancel])

  const confirmSelection = useCallback(() => {
    if (selectedMerchants.length > 0) {
      if (allowMultiple) {
        const callback = onConfirm as OnSelectMultipleMerchants
        callback(selectedMerchants)
      } else {
        const callback = onConfirm as OnSelectSingleMerchant
        callback(selectedMerchants[0])
      }
      setQuery('')
    }
  }, [selectedMerchants, allowMultiple, onConfirm])

  return (
    <ActionsDialog title={title} onCancel={cancel} onConfirm={confirmSelection} open={open} disabled={disableConfirm}>
      <Grid item container spacing={2} sx={{ mt: 5, width: 600 }}>
        <Grid item xs={12} md={14}>
          <InputTextField id="searchMerchantInput" onChange={debounceOnChange} label={label} />
        </Grid>
        <Grid item xs={12} md={14}>
          {query && query.trim() && merchants.length === 0 && (
            <Alert severity="info">{t('common.searchYieldsNoResults')}</Alert>
          )}
          {query && merchants.length >= MERCHANTS_LIMIT && (
            <Alert severity="info">
              <Trans i18nKey="common.tooManyResultsMessage" values={{ limit: MERCHANTS_LIMIT }} />
            </Alert>
          )}
          {(!query || !query.trim()) && <p>{t('common.typeToStartSearch')}</p>}
          <List
            sx={{
              width: '100%',
              maxWidth: 800,
              bgcolor: 'background.paper',
              position: 'relative',
              overflow: 'auto',
              height: 300,
              '& ul': { padding: 0 },
            }}
          >
            {!allowMultiple &&
              merchants.map((merchant) => {
                return (
                  <ListItemButton
                    id={merchant.id}
                    key={merchant.id}
                    selected={selectedMerchants.find((x) => x.id === merchant.id) !== undefined}
                    onClick={handleItemSelected}
                  >
                    <ListItemText>{merchant.name}</ListItemText>
                  </ListItemButton>
                )
              })}
            {allowMultiple &&
              merchants.map((merchant) => (
                <ListItem key={merchant.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedMerchants.find((x) => x.id === merchant.id) !== undefined}
                        onChange={handleItemChecked}
                        value={merchant.id}
                      />
                    }
                    label={merchant.name}
                  />
                </ListItem>
              ))}
          </List>
        </Grid>
        {allowMultiple && (
          <Grid item container spacing={1} sx={{ mt: 1, width: 600 }}>
            {selectedMerchants.map((merchant) => (
              <Grid item key={merchant.id}>
                <Chip label={merchant.name} />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </ActionsDialog>
  )
}
export default React.memo(SelectMerchantDialog)
